import AllIcon from '@/assets/icons/liveDrop/AllIcon';
import TopIcon from '@/assets/icons/liveDrop/TopIcon';
import ItemLiveDropComponent from '@/components/ItemComponent/ItemLiveDropComponent/ItemLiveDropComponent';
import TabItems from '@/components/styled/TabItems/TabItems';
import { APP_URLS } from '@/configs/urls';
import { useAuthContext } from '@/hooks/use-auth-context';
import { getSkinName, getWeaponName } from '@/utils/item-helpers';
import { track } from '@amplitude/analytics-browser';
import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './LiveDropFeed.module.scss';
import { useSoundContext } from 'sounds';
import { Skeleton } from '@mui/material';
import useWebSocket from 'react-use-websocket';
import { captureException } from '@sentry/nextjs';
import { useLiveDropQuery } from '@/generated/live-drop.generated';
import posthog from 'posthog-js';

export type TLiveDropItem = {
  avatar: string;
  item_class: 1 | 2;
  item_hash_name: string;
  item_image_link_formated: string;
  item_rarity: string;
  localized_name?: {
    ru_name: string | null;
  } | null;
  lootbox_image_link: string;
  lootbox_name: string;
  lootbox_slug: string;
  persona_name: string;
  user_id: string;
  drop_key: string;
};

type TLiveDropState = {
  full: TLiveDropItem[];
  top: TLiveDropItem[];
};

const LIVE_DROP_LIMIT = 11;

const socketUrl = `${process.env.NEXT_PUBLIC_WS_URL}wss_live_data?event_types=live_drop&event_sub_types=item_drop`;

/**
 * Главный компонент лайв ленты
 */

export function LiveDropFeed() {
  const { isAuthorized } = useAuthContext();

  const [activeTab, setActiveTab] = useState<'top' | 'full'>('top');
  const [liveDropItems, setLiveDropItems] = useState<TLiveDropState>({
    full: [],
    top: [],
  });

  const { loading } = useLiveDropQuery({
    onCompleted(data) {
      if (data?.TopDropStripView?.length > 0) {
        setLiveDropItems({
          full: data?.TopDropStripView[0].full_items as TLiveDropItem[],
          top: data?.TopDropStripView[0].top_items as TLiveDropItem[],
        });
      }
    },
    onError(error) {
      captureException(error);
    },
  });

  const [newItemsCount, setNewItemsCount] = useState({ full: 0, top: 0 });

  const { playSound } = useSoundContext();

  const handleLiveDropEvents = (eventItems: TLiveDropItem[] | undefined) => {
    if (!eventItems || eventItems.length === 0) {
      setNewItemsCount({ full: 0, top: 0 });
      return;
    }
    setLiveDropItems((prevItems) => {
      const newItems = { full: [...prevItems.full], top: [...prevItems.top] };

      eventItems?.forEach((item) => {
        const isItemAlreadyInFull = newItems.full.some(
          (existingItem) => existingItem.drop_key === item.drop_key
        );
        const isItemAlreadyInTop = newItems.top.some(
          (existingItem) => existingItem.drop_key === item.drop_key
        );

        if (!isItemAlreadyInFull) {
          newItems.full.unshift(item);
          if (item.item_class === 2 && !isItemAlreadyInTop) {
            newItems.top.unshift(item);
          }
        }
      });

      newItems.full = newItems.full.slice(0, LIVE_DROP_LIMIT);
      newItems.top = newItems.top.slice(0, LIVE_DROP_LIMIT);

      return newItems;
    });
    setNewItemsCount({
      full: eventItems.length,
      top: eventItems.filter((item) => item.item_class === 2).length,
    });
  };

  useWebSocket(
    socketUrl,
    {
      onMessage: (event) => {
        try {
          const { event_data } = JSON.parse(event.data);
          handleLiveDropEvents(event_data?.items);
        } catch (error) {
          captureException(error);
        }
      },
      onError: (error) => {
        captureException(error);
      },
      shouldReconnect: () => true,
    },
    typeof setLiveDropItems === 'function'
  );

  return (
    <div className={styles.container}>
      <div className={styles.container_btn}>
        <TabItems
          icon={<AllIcon />}
          onClick={() => {
            setActiveTab('full');
            track('lastdrop all', { auser: isAuthorized });
            posthog.capture('lastdrop all', {
              auser: isAuthorized,
            });
            playSound('click_general');
          }}
          active={activeTab === 'full'}
          className={styles.icon}
          text="ВСЕ"
        />

        <TabItems
          icon={<TopIcon />}
          onClick={() => {
            setActiveTab('top');
            track('lastdrop top', { auser: isAuthorized });
            posthog.capture('lastdrop top', {
              auser: isAuthorized,
            });
            playSound('click_general');
          }}
          active={activeTab === 'top'}
          className={styles.icon}
          text="ТОП"
        />
      </div>

      <div className={styles.scrollWrapper}>
        {!loading && liveDropItems[activeTab]?.length > 0 ? (
          <TransitionGroup className={styles.scroll}>
            {liveDropItems[activeTab].map((item, index) => {
              return (
                <CSSTransition
                  key={item.drop_key + activeTab}
                  timeout={0}
                  classNames={{
                    enter: styles.itemEnter,
                    enterActive: styles.itemEnterActive,
                    enterDone: styles.itemDone,
                    appear: styles.item,
                  }}
                  style={{
                    '--position': index,
                    '--new-elements-count': newItemsCount[activeTab],
                  }}
                  onEntered={() => setNewItemsCount({ full: 0, top: 0 })}
                  in
                  appear
                  unmountOnExit
                >
                  <div>
                    <ItemLiveDropComponent
                      weaponName={getWeaponName(
                        item.localized_name?.ru_name || item.item_hash_name
                      )}
                      skinName={getSkinName(
                        item.localized_name?.ru_name || item.item_hash_name
                      )}
                      rarity={item.item_rarity}
                      img={item.item_image_link_formated}
                      userAvatar={item.avatar}
                      userName={item.persona_name}
                      caseImg={item.lootbox_image_link}
                      userLink={APP_URLS.getUserPage(item.user_id)}
                    />
                  </div>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        ) : (
          <div className={styles.scrollSkeleton}>
            {Array.from({ length: LIVE_DROP_LIMIT }).map((_, index) => (
              <Skeleton
                key={index}
                sx={{
                  bgcolor: 'rgb(25, 31, 64)',
                  borderRadius: '8px',
                  flexShrink: 0,
                }}
                variant="rectangular"
                width={164}
                height={92}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default LiveDropFeed;
