import gift from '../assets/images/socialLink/newPage/heart.svg';
import imgFAQ from '../assets/images/socialLink/newPage/faq.svg';
import document from '../assets/images/socialLink/newPage/document.svg';
import newBonus from '@/assets/images/menu/newBonus.webp';
import store from '@/assets/images/menu/store.webp';
import newHDCoin from '@/assets/images/menu/newHDCoin.webp';
import newUpgrade from '@/assets/images/menu/newUpgrade.webp';
import newWheel from '@/assets/images/menu/newWheel.webp';
import newGiveaway from '@/assets/images/menu/newGiveaway.webp';
import vk_login from '@/assets/icons/login/vk.svg';
import steam_login from '@/assets/icons/login/steam.svg';
import google_login from '@/assets/icons/login/google.svg';
import telegram from '../assets/images/socialLink/telegram.svg';
import vk from '../assets/images/socialLink/vk.svg';
import discord from '../assets/images/socialLink/discord.svg';
import imgUpgrade from '../assets/images/socialLink/newPage/cup.svg';
import wheelIcon from '../assets/images/socialLink/drum.svg';
import giveAway from '../assets/icons/give/giveaway.svg';

export const MAIN_URL = 'https://hyper-drop.com/';

// Все URL приложения
export const APP_URLS = {
  HOME: '/',
  SIGN_IN: '/sign_in',
  SIGN_OUT: '/sign_out',
  PAY_UP:
    'https://hyper-drop.com/hd_proccess_ps_main?amount=300&user_ip=127.0.0.1',

  USER_PAGE: {
    ROOT: '/user/[id]',
    USER_ITEMS: '/user/[id]/items',
    USER_CONTRACTS: '/user/[id]/contracts',
    USER_UPGRADES: '/user/[id]/upgrades',

    USER_PAYMENT: '/user/[id]/payment',
  },

  PROFILE: '/profile',

  getUserPage: (userId: string) => `/user/${userId}`,

  CASE_PAGE: '/case',
  getCasePage: (caseSlug: string) => `/case/${caseSlug}`,
  getFreeCasePage: (caseSlug: string) => `/case/free/${caseSlug}`,
  getBonusCasePage: (caseSlug: string) => `/case/bonus/${caseSlug}`,
  getGiveawayPage: (id: string) => `/give/${id}`,
  BONUS_PAGE: '/bonuses',
  WHEEL_PAGE: '/wheel',
  ACTION_PAGE: '/stocks',

  WELCOME_PAGE: '/welcome',
  UPGRADE_PAGE: '/upgrade',
  MISSIONS_PAGE: '/missions',
  GIVE_PAGE: '/give?type=active',
  CONTRACTS_PAGE: '/soon',
  FAQ_PAGE: '/faq',
  MESSAGE_PAGE: '/soon',
  PARTNER_PAGE: '/partnership?tab=overview',
  BIG_DRAW_PAGE: '/event/big_draw',
  WHICH_IS_COOLER: '/which-is-cooler',
  STORE: '/store',
  SOCIALS: {
    VK: 'https://vk.com/hyperdropcom',
    YOUTUBE: 'https://www.youtube.com/@hyper-drop/shorts',
    TELEGRAM: 'https://t.me/hyperdropcom',
    TWITCH: 'https://www.twitch.tv/hyperdropcom',
    DISCORD: 'https://discord.gg/RXwdd8Sbuk',
  },

  FOOTER: {
    TERMS: '/agreement',
    PRIVACY: '/soon',
    CONTACTS: '/contacts',
  },

  SIGN_IN_TYPE: {
    SIGN_IN_STEAM: '/sign_in_steam',
    SIGN_IN_GOOGLE: '/sign_in_google',
    SIGN_IN_VK: '/sign_in_vk',
  },
};

export const supportLinks = {
  TELEGRAM_BOT: 'https://t.me/Hyperdrop_bot',
  TELEGRAM_ADMIN: 'https://t.me/hyperdrop_admin',
};

export const navButtons = [
  // {
  //   title: 'Бонусы',
  //   href: APP_URLS.BONUS_PAGE,
  //   icon: imgBonus,
  //   icon2xSize: imgBonus2x,
  // },
  // {
  //   title: 'Барабан',
  //   href: APP_URLS.DRUM_PAGE,
  //   icon: imgDrum,
  //   icon2xSize: imgDrum2x,
  // },
  // {
  //   title: 'Акции',
  //   href: APP_URLS.ACTION_PAGE,
  //   icon: imgMoney,
  //   icon2xSize: imgMoney2x,
  // },
];

export const navLinks = [
  {
    title: 'Giveaway',
    href: APP_URLS.GIVE_PAGE,
    icon: giveAway,
    text: 'Раздача игровых призов',
    image: newGiveaway,
  },
  {
    title: 'Халява',
    href: APP_URLS.WELCOME_PAGE,
    icon: newHDCoin,
    text: 'Выполнено: ',
    image: newHDCoin,
  },
  {
    title: 'Миссии',
    href: APP_URLS.MISSIONS_PAGE,
    icon: newBonus,
    text: 'Зарабатывайте hd coin',
    image: newBonus,
  },
  {
    title: 'Барабан',
    href: APP_URLS.WHEEL_PAGE,
    icon: wheelIcon,
    image: newWheel,
  },
  {
    title: 'Апгрейд',
    href: APP_URLS.UPGRADE_PAGE,
    icon: imgUpgrade,
    text: 'Из 💩 в 🍬 за один клик',
    image: newUpgrade,
  },
  {
    title: 'Магазин',
    href: APP_URLS.STORE,
    icon: store,
    image: store,
    text: 'Покупки за HD Coins',
  },
];

export const socialLinks = [
  {
    href: APP_URLS.SOCIALS.TELEGRAM,
    icon: telegram,
    name: 'tg',
  },
  {
    href: APP_URLS.SOCIALS.VK,
    icon: vk,
    name: 'vk',
  },
  // {
  //   href: APP_URLS.SOCIALS.TWITCH,
  //   icon: twitch,
  // },
  {
    href: APP_URLS.SOCIALS.DISCORD,
    icon: discord,
    name: 'discord',
  },
];

export const footerLinksBottom = [
  {
    title: 'Пользовательское соглашение',
    href: APP_URLS.FOOTER.TERMS,
  },
];

export const footerLinks = [
  // {
  //   title: 'Соглашение о приватности',
  //   href: APP_URLS.FOOTER.PRIVACY,
  // },
  {
    title: 'Партнерка',
    href: APP_URLS.PARTNER_PAGE,
    icon: gift,
  },
  {
    title: 'Контакты',
    href: APP_URLS.FOOTER.CONTACTS,
    icon: document,
  },
  {
    title: 'FAQ',
    href: APP_URLS.FAQ_PAGE,
    icon: imgFAQ,
  },
  // {
  //   title: 'Пополнить баланс',
  //   href: APP_URLS.USER_PAGE.USER_PAYMENT,
  //   withId: true,
  // },
];

export const navLinksFooter = [
  // {
  //   title: 'Акции',
  //   href: APP_URLS.ACTION_PAGE,
  //   icon: lighthing,
  // },
  // {
  //   title: 'Бонусы',
  //   href: APP_URLS.BONUS_PAGE,
  //   icon: gift,
  // },
];

export const signInType = [
  {
    name: 'vk',
    href: APP_URLS.SIGN_IN_TYPE.SIGN_IN_VK,
    icon: vk_login,
  },
  {
    name: 'steam',
    href: APP_URLS.SIGN_IN_TYPE.SIGN_IN_STEAM,
    icon: steam_login,
  },
  {
    name: 'google',
    href: APP_URLS.SIGN_IN_TYPE.SIGN_IN_GOOGLE,
    icon: google_login,
  },
];
