import { APP_URLS } from '@/configs/urls';
import { useRefreshTokenMutationMutation } from '@/generated/projectlb-hasura';
import { parseJwt } from '@/utils/parse-jwt';
import { reset, setUserId, track } from '@amplitude/analytics-browser';
import axios from 'axios';
import dayjs from 'dayjs';
import { atom, useAtom } from 'jotai';
import React, { useCallback, useContext, useEffect } from 'react';
import client from '../../apollo-client';
import { captureException } from '@sentry/nextjs';
import posthog from 'posthog-js';

const KEY = 'hd_token';

interface AuthState {
  isAuthorized: boolean;
  accessToken?: string;
  gettingDate?: Date;
  userId?: string;
  walletId?: string;
  balance?: number;
}

interface IAuthContext extends AuthState {
  startAuthSession: (token: string) => void;
  stopAuthSession: () => void;
  refreshTokenMutation: () => void;
}

const authContext = React.createContext<IAuthContext | null>(null);

/** Получить время в секундах, через которые токен устареет на основе даты выдачи и времени его жизни*/
const getTokenExpirationTime = (
  gettingAccessTokenDate: Date,
  accessTokenLifeTime: number
) =>
  dayjs(dayjs(gettingAccessTokenDate)).diff(new Date(), 'seconds') +
  accessTokenLifeTime * 60;

/** Получение токена из кеша если он там есть */
const getFromCache = () => {
  try {
    const storeDataString = localStorage.getItem(KEY);
    if (!storeDataString) return null;

    const storeData: Partial<AuthState> = JSON.parse(storeDataString);

    const lastAccessToken = storeData.accessToken;
    const tokenLastDate = storeData.gettingDate;

    if (lastAccessToken && tokenLastDate) {
      if (getTokenExpirationTime(tokenLastDate, 600) > 30)
        return storeData as AuthState;
    }
  } catch (error) {
    captureException(error);
  }

  return null;
};

//** Атом состояние авторизации. По умолчанию значение берется из кэша (если он есть) */
const authAtom = atom<AuthState>(
  getFromCache() || {
    isAuthorized: false,
  }
);

/** Атом авторизации с записью кэша в LocalStorage */
const authAtomWithPersistence = atom<AuthState, AuthState>(
  (get) => get(authAtom),
  (_, set, newVal) => {
    set(authAtom, newVal);
    localStorage.setItem(KEY, JSON.stringify(newVal));
  }
);

export const AuthProvider = (props: { children: React.ReactNode }) => {
  const tokenUpdaterTimer = React.useRef<NodeJS.Timeout | null>(null);
  const [authState, setAuthData] = useAtom(authAtomWithPersistence);

  const startAuthSession = useCallback(
    async (token: string, newTimeToRequest?: number) => {
      const { userID } = parseJwt(token);

      if (tokenUpdaterTimer.current) clearTimeout(tokenUpdaterTimer.current);

      /** Периодически обновляю токен, чтоб не устаревал */
      tokenUpdaterTimer.current = setTimeout(
        () => {
          refreshTokenMutation();
        },
        (newTimeToRequest || 57) * 1000 * 10
      ); // обновляю токен за 30 сек до смерти

      setAuthData({
        ...authState,
        isAuthorized: true,
        accessToken: token,
        gettingDate: new Date(),
        userId: userID,
      });
      setUserId(userID);
    },
    [authState]
  );

  const stopAuthSession: IAuthContext['stopAuthSession'] =
    useCallback(async () => {
      setAuthData({
        isAuthorized: false,
        userId: undefined,
        accessToken: undefined,
      });
      localStorage.removeItem(KEY);
      track('logout', { auser: authState.isAuthorized });
      posthog.capture('logout', { auser: authState.isAuthorized });

      axios.get(APP_URLS.SIGN_OUT);
      // sessionStorage.setItem('skipAuthEvent', 'false');
      reset();
    }, []);

  const [refreshTokenMutation, {}] = useRefreshTokenMutationMutation({
    client: client,
    fetchPolicy: 'no-cache',
    onError: (error) => {
      captureException(error);
      setAuthData({
        ...authState,
        isAuthorized: false,
      });
      localStorage.removeItem(KEY);
      if (tokenUpdaterTimer.current) clearTimeout(tokenUpdaterTimer.current);
    },
    onCompleted: ({ refreshToken }) => {
      if (refreshToken?.access_token) {
        startAuthSession(refreshToken.access_token);
      }
    },
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) refreshTokenMutation();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    if (
      authState.isAuthorized &&
      authState.accessToken &&
      authState.gettingDate
    ) {
      const cachedTokenTimeLife = getTokenExpirationTime(
        authState.gettingDate,
        600
      );
      if (cachedTokenTimeLife > 30) {
        startAuthSession(
          authState.accessToken,
          Math.min(cachedTokenTimeLife, 1 / 6)
        );
      } else refreshTokenMutation();
    } else refreshTokenMutation();

    return () => {
      if (tokenUpdaterTimer.current) clearTimeout(tokenUpdaterTimer.current);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (authState.userId) {
      posthog.identify(authState.userId);
    }
  }, [authState.userId]);

  return (
    <authContext.Provider
      value={{
        ...authState,
        stopAuthSession,
        refreshTokenMutation,
        startAuthSession,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export function useAuthContext() {
  const context = useContext(authContext);
  if (context === null) throw new Error('Отсутствует AuthProvider');
  return context;
}
