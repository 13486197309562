import React from 'react';

interface SkewedSVGProps {
  color: string;
  height?: number;
}

const SkewedSVG1: React.FC<SkewedSVGProps> = ({ color, height }) => (
  <svg
    width="10"
    height={height || '32'}
    viewBox={`0 0 10 ${height}` || '0 0 10 32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.83273 3.46747C4.09944 1.48198 5.7938 0 7.79712 0H10.064V32H4.57324C2.15251 32 0.286569 29.8666 0.608847 27.4675L3.83273 3.46747Z"
      fill={color}
    />
  </svg>
);

const SkewedSVG2: React.FC<SkewedSVGProps> = ({ color, height }) => (
  <svg
    width="10"
    height={height || '32'}
    viewBox={`0 0 10 ${height}` || '0 0 10 32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: 'rotate(0deg) scale(-1, -1)' }}
  >
    <path
      d="M3.83273 3.46747C4.09944 1.48198 5.7938 0 7.79712 0H10.064V32H4.57324C2.15251 32 0.286569 29.8666 0.608847 27.4675L3.83273 3.46747Z"
      fill={color}
    />
  </svg>
);

export { SkewedSVG1, SkewedSVG2 };
