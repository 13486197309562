import { signInType } from '@/configs/urls';
import { useAuthContext } from '@/hooks/use-auth-context';
import { passAmpliSessionIdToCookie } from '@/utils/ampli-session-cookie';
import { setReturnPageCookie } from '@/utils/set-return-page-cookie';
import { track } from '@amplitude/analytics-browser';
import { Typography } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styles from './LoginButton.module.scss';
import { useEffect } from 'react';
import { useWheelUserTicketInfoLazyQuery } from '@/generated/wheel.generated';
import posthog from 'posthog-js';

const LoginButton = ({
  from,
  darkBg,
  openPayModal,
}: {
  from: string;
  darkBg?: boolean;
  openPayModal?: boolean;
}) => {
  const router = useRouter();
  const { isAuthorized, accessToken } = useAuthContext();
  const [WheelUserTicketInfoQuery] = useWheelUserTicketInfoLazyQuery();
  useEffect(() => {
    if (isAuthorized && accessToken) {
      WheelUserTicketInfoQuery({
        context: { token: accessToken },
      });
    }
  }, [isAuthorized, accessToken]);
  const handleClick = (name: string, href: string) => {
    setReturnPageCookie(router.asPath, openPayModal);
    passAmpliSessionIdToCookie();
    posthog.capture('sign_in start', {
      type: name,
      auser: isAuthorized,
      from: from,
    });
    track('sign_in start', {
      type: name,
      auser: isAuthorized,
      from: from,
    }).promise.then(() => router.push(href));
  };

  return (
    <div
      className={styles.container}
      style={{ background: darkBg ? '#0c1027' : '#191f40' }}
    >
      <Typography className={styles.text}>
        Войти <span>через</span>
      </Typography>
      <div className={styles.type_login}>
        {signInType.map((type, index) => {
          return (
            <button
              key={index}
              onClick={() => handleClick(type.name, type.href)}
              style={{ background: darkBg ? '#191f40' : '#0c1027' }}
            >
              <Image src={type.icon} alt={`Вход ${type.name}`} />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default LoginButton;
