import coin from '@/assets/icons/HDCoinSmall.svg';
import logout from '@/assets/icons/logoutMenu.svg';
import noAvatar from '@/assets/images/profile.svg';
import { MediaLink } from '@/components/MediaLink/MediaLink';
import { APP_URLS, footerLinks, navLinks, socialLinks } from '@/configs/urls';
import { useAuthContext } from '@/hooks/use-auth-context';
import { useBalance } from '@/hooks/use-balance';
import { useUserData } from '@/hooks/use-user-data';
import { formatNumber } from '@/utils/format-number';
import Button from '@mui/material/Button';
import { useAtomValue } from 'jotai';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styles from './Menu.module.scss';
import { useSoundContext } from 'sounds';
import { missionCountAtom } from 'atoms/mission-count-atom';
import SoundsToggle from './SoundsToggle/SoundsToggle';
import golden from '@/assets/icons/baraban/golden.svg';
import silver from '@/assets/icons/baraban/silver.svg';
import iron from '@/assets/icons/baraban/bronze.svg';
import OptimizedImage from '@/components/Image';
import { PromoBlock } from '@/components/Authorized/PromoBlock/PromoBlock';
import { theme } from '@/styles/theme';
import { useMediaQuery } from '@mui/material';
import { useWheelUserTicketInfoLazyQuery } from '@/generated/wheel.generated';
import { userOnlineAtom } from 'atoms/user-online-atom';
import onlineIcon from '@/assets/icons/menu/onlineIcon.svg';

const isStage = process.env.NEXT_PUBLIC_APP_ENV === 'development';
const fakeUserUrl = `${process.env.NEXT_PUBLIC_WEBSITE_URL}sign_fake_user`;

export interface IMenu {
  open: boolean;
  close: Function;
}

/**
 * Всплывающее меню по нажатию на бургер
 */
export default function Menu(props: IMenu) {
  const router = useRouter();
  const { stopAuthSession, isAuthorized, userId, accessToken } =
    useAuthContext();

  const { playSound, isSoundEnabled, toggleSound } = useSoundContext();

  function closeMenu() {
    props.close();
  }

  const onClickLogout = () => {
    stopAuthSession();
    playSound('click_general');
  };

  const missionsCount = useAtomValue(missionCountAtom);
  const { avatar } = useUserData(userId);
  const { bonusBalance } = useBalance();

  const [getWheelUserTickets, { data: WheelUserTicketInfo }] =
    useWheelUserTicketInfoLazyQuery();

  useEffect(() => {
    router.events.on('routeChangeComplete', closeMenu);

    return () => {
      router.events.off('routeChangeComplete', closeMenu);
    };
  }, []);

  useEffect(() => {
    getWheelUserTickets({
      fetchPolicy: 'no-cache',
      context: { token: accessToken },
    });
  }, [accessToken]);

  function getTicketImage(type: string) {
    switch (type) {
      case 'iron':
        return iron;
      case 'silver':
        return silver;
      case 'gold':
        return golden;
      default:
        return '';
    }
  }

  const mobilePromo = useMediaQuery(theme.breakpoints.down(1150));
  const mobileUserCount = useMediaQuery(theme.breakpoints.down(900));

  function getTicketCount(type: string) {
    const ticket = WheelUserTicketInfo?.WheelUserTicketInfo?.tickets?.find(
      (t) => t?.ticket_type_name === type
    );
    return ticket ? ticket.ticket_count : 0;
  }

  const userCount = useAtomValue(userOnlineAtom);

  return (
    <>
      {props.open && (
        <div className={styles.container}>
          <div className={styles.main}>
            {mobileUserCount && (
              <div className={styles.onlineUserCount}>
                <div className={styles.imageContainer}>
                  <div>
                    <Image src={onlineIcon} alt="Online Icon" />
                  </div>
                </div>

                <div className={styles.countTxt}>
                  Online {userCount.toLocaleString('ru-Ru')}
                </div>
              </div>
            )}
            {isAuthorized && (
              <div className={styles.userWrap}>
                <div className={styles.user_container}>
                  <Link href={APP_URLS.PROFILE} passHref>
                    <a
                      className={styles.user_info}
                      onClick={() => playSound('click_general')}
                    >
                      <div className={styles.avatarBack_container}>
                        <OptimizedImage
                          src={avatar ? avatar : noAvatar}
                          alt="User avatar"
                          layout={avatar ? 'fill' : undefined}
                          width="80px"
                          height="80px"
                        />
                      </div>
                      <p className={styles.name}>Профиль</p>
                    </a>
                  </Link>
                  <div className={styles.containerCoin}>
                    <Image src={coin} alt="Монетка" width={16} height={16} />
                    <p className={styles.coin}>{formatNumber(bonusBalance)}</p>
                  </div>
                </div>
              </div>
            )}

            {mobilePromo && (
              <div className={styles.promoBlockCon}>
                <PromoBlock />
              </div>
            )}

            <div className={styles.menuButton}>
              <>
                {navLinks.map((item) => {
                  const showMissionsProgress = item.title === 'Халява';
                  const showTicket = item.title === 'Барабан';
                  return (
                    <Link key={item.href} href={item.href} onClick={closeMenu}>
                      <div className={styles.menuButtonLink}>
                        <Button
                          color="mainGreen"
                          className={styles.link}
                          key={item.title}
                          variant="outlined"
                          onClick={() => playSound('click_general')}
                        >
                          <div className={styles.container_link}>
                            <p className={styles.text}>{item.title}</p>
                            <div className={styles.bonusContainer}>
                              {item.text && (
                                <p className={styles.textInfo}>{item.text}</p>
                              )}
                              {showMissionsProgress && (
                                <div className={styles.bonuses}>
                                  <p className={styles.text_bonus}>
                                    {`${missionsCount?.completed} / ${missionsCount?.all}`}
                                  </p>
                                </div>
                              )}
                              {showTicket && (
                                <div className={styles.bonusContainerMenu}>
                                  {['iron', 'silver', 'gold'].map((type) => (
                                    <div className={styles.bonusCon} key={type}>
                                      <Image
                                        src={getTicketImage(type)}
                                        alt={`${type} ticket`}
                                      />
                                      <div className={styles.barabanText}>
                                        <p>{getTicketCount(type)}/5</p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </Button>
                        <div
                          className={styles.imageCon}
                          style={{
                            width: item.title === 'Магазин' ? 'auto' : '25%',
                          }}
                        >
                          <Image src={item.image!} alt="" />
                        </div>
                      </div>
                    </Link>
                  );
                })}

                <div className={styles.linkCon}>
                  {footerLinks.map((item) => (
                    <Link href={item.href} key={item.title}>
                      <Button
                        color="info"
                        className={styles.otherButton}
                        variant="outlined"
                        onClick={() => {}}
                      >
                        <Image src={item.icon} alt="" />
                        <p>{item.title}</p>
                      </Button>
                    </Link>
                  ))}
                  <SoundsToggle
                    toggleSound={toggleSound}
                    isSoundEnabled={isSoundEnabled}
                  />
                  {isAuthorized && (
                    <Button
                      className={styles.otherButton}
                      color="info"
                      variant="outlined"
                      onClick={onClickLogout}
                    >
                      <Image src={logout} alt="logout" />
                      <p className={styles.logoutText}>Выйти</p>
                    </Button>
                  )}
                  {isStage && !isAuthorized && (
                    <Link href={fakeUserUrl}>
                      <a>
                        <Button variant="text" color="mainGreen">
                          Create fake user
                        </Button>
                      </a>
                    </Link>
                  )}
                </div>
              </>
            </div>
          </div>

          <div className={styles.mediaLinks}>
            {socialLinks.map((item) => {
              return (
                <MediaLink
                  icon={item.icon}
                  href={item.href}
                  key={item.href}
                  name={item.name}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
