import styles from './Ribbon.module.scss';
import Image from 'next/image';
import fire from '@/assets/icons/fire.svg';
import { SkewedSVG1, SkewedSVG2 } from './border';
import Timer, { dateToUtc } from './timer';

interface RibbonProps {
  text: string;
  height?: number;
}

interface RibbonTimerProps {
  startDate: number;
  endDate: number;
}

const colorMapping: { [key: string]: string } = {
  'Ограниченное предложение': '#652de5',
  'Горячее предложение': '#d62748',
  'Супер выгода': '#2dcf60',
  Популярно: '#caab27',
  'Ограничено по времени': '#DA47FD',
  '+25% к депозиту': '#F85A5A',
};

export const Ribbon: React.FC<RibbonProps> = ({ text, height }) => {
  const color = colorMapping[text] || '#000';

  return (
    <div className={styles.container}>
      <SkewedSVG1 color={color} height={height}/>

      <div className={styles.text} style={{ background: color }}>
        <Image src={fire} width={16} height={16} />
        <p>{text}</p>
      </div>

      <SkewedSVG2 color={color} height={height}/>
    </div>
  );
};

export const RibbonTimer: React.FC<RibbonTimerProps> = ({
  startDate,
  endDate,
}) => {
  const color = '#000000';
  const currentTime = dateToUtc();

  return (
    <div className={styles.container}>
      <SkewedSVG1 color={color} />

      <div className={styles.textTimer} style={{ background: color }}>
        {currentTime < endDate ? (
          <Timer endDate={endDate} startDate={startDate} />
        ) : (
          <>
            <Image src={fire} width={16} height={16} />
            <p>Предложение завершено</p>
          </>
        )}
      </div>

      <SkewedSVG2 color={color} />
    </div>
  );
};
