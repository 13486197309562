import { createContext, useContext, useEffect, useState } from 'react';
import { Howl, Howler } from 'howler';
import { useAuthContext } from '@/hooks/use-auth-context';
import { track } from '@amplitude/analytics-browser';
import { useLocalStorage } from '@/utils/use-local-storage';

type SoundConfig = {
  [key: string]: {
    src: string;
    loop: boolean;
    rate?: number;
  };
};

type SoundList = {
  [key: string]: Howl;
};

type SoundContextType = {
  playSound: (soundKey: string, rate?: number, duration?: number) => void;
  stopSound: (soundKey: string) => void;
  toggleSound: () => void;
  isSoundEnabled?: boolean;
};

const soundConfig: SoundConfig = {
  click_general: { src: '/sounds/Click_general.webm', loop: false },
  click_accomplish: { src: '/sounds/Click_accomplish.webm', loop: false },
  case_win: { src: '/sounds/Case_win.webm', loop: false },
  case_loose: { src: '/sounds/Case_loose.webm', loop: false },
  reel_upgrade: { src: '/sounds/Reel_upgrade.webm', loop: true, rate: 2 },
  reel_case: { src: '/sounds/Reel_case.webm', loop: true, rate: 1 },
  notifications: { src: '/sounds/notification.webm', loop: false },
  btn_begin_upgrade: { src: '/sounds/Button_begin_upgrade.webm', loop: false },
  btn_inventory: { src: '/sounds/inventory.webm', loop: false },
  btn_sell_item: { src: '/sounds/Button_sell.webm', loop: false },
  btn_open: { src: '/sounds/Case_open.webm', loop: false },
  btn_case_upgrade: { src: '/sounds/Button_upgrade.webm', loop: false },
};

const LOCAL_STORAGE_SOUND_KEY = 'isSoundEnabled';

const SoundContext = createContext<SoundContextType | null>(null);

export function SoundProvider({ children }: any) {
  const { isAuthorized } = useAuthContext();
  const [sounds, setSounds] = useState<SoundList | null>(null);
  const [isSoundEnabled, setIsSoundEnabled] = useLocalStorage<boolean>(
    LOCAL_STORAGE_SOUND_KEY,
    true,
    undefined,
    String
  );

  const playSound = (
    soundKey: string,
    rate?: number,
    fadeDuration?: number
  ) => {
    if (isSoundEnabled && sounds) {
      const sound = sounds[soundKey];
      if (sound) {
        try {
          const initialRate = soundConfig[soundKey].rate || 1;

          if (fadeDuration) {
            const rateChangeInterval = 50;
            const rateChangeSteps = fadeDuration / rateChangeInterval;
            const rateChangePerStep = (initialRate - rate!) / rateChangeSteps;
            let currentStep = 0;

            const rateChangeIntervalId = setInterval(() => {
              currentStep++;
              const newRate = initialRate - rateChangePerStep * currentStep;
              sound.rate(newRate);

              if (currentStep >= rateChangeSteps) {
                clearInterval(rateChangeIntervalId);
              }
            }, rateChangeInterval);
          }

          sound.rate(initialRate);
          track('sound played', { auser: isAuthorized, subevent: soundKey });
          sound.play();
        } catch {}
      }
    }
  };

  const stopSound = (soundKey: string) => {
    if (isSoundEnabled && sounds) {
      const sound = sounds[soundKey];
      if (sound) {
        sound.stop();
      }
    }
  };

  const toggleSound = () => {
    const newIsSoundEnabled = !isSoundEnabled;
    playSound('click_general');
    track('sound', {
      auser: isAuthorized,
      subevent: newIsSoundEnabled ? 'on' : 'off',
    });

    setIsSoundEnabled(newIsSoundEnabled);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('isSoundEnabled', newIsSoundEnabled.toString());
    }
  };

  useEffect(() => {
    const loadedSounds: SoundList = {};
    for (const key in soundConfig) {
      const sound = new Howl({
        src: [soundConfig[key].src],
        loop: soundConfig[key].loop,
      });
      loadedSounds[key] = sound;
    }
    setSounds(loadedSounds);
  }, []);

  useEffect(() => {
    if (sounds) {
      Howler.mute(!isSoundEnabled);
    }
  }, [isSoundEnabled]);

  return (
    <SoundContext.Provider
      value={{ playSound, stopSound, toggleSound, isSoundEnabled }}
    >
      {children}
    </SoundContext.Provider>
  );
}

export function useSoundContext() {
  const context = useContext(SoundContext);
  if (!context) {
    throw new Error('useSoundContext must be used within a SoundProvider');
  }
  return context;
}
