import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './timer.module.scss';

export const dateToUtc = () => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return new Date(formattedDate).getTime();
};

export const getLabel = (timeLeft: number, startDate: number) => {
  if (timeLeft <= 0) {
    return 'Завершено';
  } else if (startDate <= dateToUtc()) {
    return 'до розыгрыша';
  } else {
    return 'до старта';
  }
};

export const getTimeObject = (time: number) => {
  return {
    days: Math.floor(time / 86400),
    hours: Math.floor((time % 86400) / 3600),
    minutes: Math.floor((time % 3600) / 60),
    seconds: time % 60,
  };
};

const Timer = ({
  endDate,
  startDate,
}: {
  endDate: number;
  startDate: number;
}) => {
  const [timeLeftEnd, setTimeLeftEnd] = useState(
    Math.max(0, Math.floor((startDate - dateToUtc()) / 1000))
  );
  const [timeLeft, setTimeLeft] = useState(
    Math.max(0, Math.floor((endDate - dateToUtc()) / 1000))
  );

  const progressValue =
    getLabel(timeLeft, startDate) === 'до старта'
      ? Math.min(
          100,
          Math.floor(
            (Math.max(0, startDate - dateToUtc()) / (endDate - startDate)) * 100
          )
        )
      : Math.max(0, ((endDate - dateToUtc()) / (endDate - startDate)) * 100);

  useEffect(() => {
    let intervalId1: NodeJS.Timeout;
    let intervalId2: NodeJS.Timeout;
    if (getLabel(timeLeft, startDate) === 'до старта') {
      intervalId1 = setInterval(() => {
        setTimeLeftEnd((prev: number) => {
          return prev - 1;
        });
      }, 1000);
    }
    if (getLabel(timeLeft, startDate) === 'до розыгрыша') {
      intervalId2 = setInterval(() => {
        setTimeLeft((prev: number) => {
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (intervalId1) {
        clearInterval(intervalId1);
      }
      if (intervalId2) {
        clearInterval(intervalId2);
      }
    };
  }, [timeLeft, timeLeftEnd]);

  let progressColor;
  if (progressValue > 75) {
    progressColor = 'green';
  } else if (progressValue > 50) {
    progressColor = 'orange';
  } else {
    progressColor = 'red';
  }

  const timeObject = getTimeObject(timeLeft);
  const timeObjectEnd = getTimeObject(timeLeftEnd!);

  const { days, hours, minutes, seconds } =
    getLabel(timeLeft, startDate) === 'до розыгрыша'
      ? timeObject
      : timeObjectEnd;
  return (
    <div className={styles.container_date}>
      <CircularProgress
        variant="determinate"
        value={progressValue}
        className={styles.date_circle}
        style={{ color: progressColor }}
        thickness={8}
        size={16}
      />
      <div className={styles.container_date_info}>
        <p className={styles.timer_text}>
          {days > 0 && `${days}д `}
          {hours.toString().padStart(2, '0')}:
          {minutes.toString().padStart(2, '0')}:
          {seconds.toString().padStart(2, '0')}
        </p>
      </div>
    </div>
  );
};

export default Timer;
