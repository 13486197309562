import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './CircularProgressWrapper.module.scss';

interface CircularProgressWrapperProps {
  progressValue: number;
  progressColor: string;
  timerText: string;
}

const CircularProgressWrapper: React.FC<CircularProgressWrapperProps> = ({
  progressValue,
  progressColor,
  timerText,
}) => {
  return (
    <div className={styles.circularProgressWrapper}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={`${styles.date_circle} ${styles.date_circle_outline}`}
        style={{ color: '#0c1027' }}
        thickness={8}
      />
      <CircularProgress
        variant="determinate"
        value={progressValue}
        className={styles.date_circle}
        style={{ color: progressColor }}
        thickness={8}
      />
      <p className={styles.timerText}>{timerText}</p>
    </div>
  );
};

export default CircularProgressWrapper;
