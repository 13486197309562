import { track } from '@amplitude/analytics-browser';
import { useRouter } from 'next/router';
import { useAuthContext } from './use-auth-context';
import posthog from 'posthog-js';

interface IErrorProperties {
  message: string;
  from?: string;
}

export const useAmpliError = () => {
  const { asPath } = useRouter();
  const { isAuthorized } = useAuthContext();

  const errorTrack = ({ message, from = asPath }: IErrorProperties) => {
    posthog.capture('error', {
      msg: message,
      from: from,
      auser: isAuthorized,
    });
    track('error', { msg: message, from: from, auser: isAuthorized });
  };
  return { errorTrack };
};
