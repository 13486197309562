import { Ribbon } from '../case/CaseFree/Ribbon/Ribbon';
import CentreButton from '../styled/CustomButtons/CentreButton/CentreButton';
import styles from './ModalFirstDep.module.scss';
import Image from 'next/image';
import img1 from '@/assets/images/modalFirstDep/img1.webp';
import img2 from '@/assets/images/modalFirstDep/img2.webp';
import CircularProgressWrapper from '../Pay/PayPromo/CircularProgressTimer/CircularProgressWrapper';
import ellipseMini from '@/assets/images/modalFirstDep/ellipseMini.svg';
import ellipseBig from '@/assets/images/modalFirstDep/ellipseBig.svg';
import ellipseTop from '@/assets/images/modalFirstDep/ellipseTop.svg';
import imgBottom from '@/assets/images/modalFirstDep/imgBottom.webp';
import closeIcon from '@/assets/icons/pay/close.svg';

const ModalFirstDep = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <div className={styles.modalContent}>
        <div className={styles.ellipseTop}>
          <Image src={ellipseTop} />
        </div>

        <div className={styles.img1}>
          <Image src={img1} />
        </div>
        <div className={styles.img2}>
          <Image src={img2} className={styles.img2} />
        </div>

        <div className={styles.ellipseMini}>
          <Image src={ellipseMini} />
        </div>

        <div className={styles.ellipseBig}>
          <Image src={ellipseBig} />
        </div>

        <div className={styles.textModal}>
          <h2 className={styles.modalTitle}>
            Твой приветственный бонус уже готов!
          </h2>
          <p className={styles.modalSubtitle}>
            Твой промокод на 1й депозит уже в кассе
          </p>
        </div>

        <div className={styles.promoContainer}>
          <div className={styles.badge}>
            <Ribbon text={'+25% к депозиту'} height={24} />
          </div>
          <div className={styles.circularProgress}>
            <div className={styles.wrapper}>
              <div className={styles.bg}></div>
              <CircularProgressWrapper
                progressValue={80}
                progressColor={'green'}
                timerText={'24:25'}
              />
            </div>
          </div>
          <div className={styles.promoCode}>
            <p>NEWBIE2024</p>
          </div>
        </div>
        <div className={styles.imgBottom}>
          <Image src={imgBottom}/>
        </div>
        <div className={styles.actionButton}>
          <CentreButton text={'Забрать'} />
        </div>
        <div className={styles.closeButton} onClick={onClose}>
          <Image src={closeIcon}/>
        </div>
      </div>
    </>
  );
};

export default ModalFirstDep;
