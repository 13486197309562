import { useAuthContext } from '@/hooks/use-auth-context';
import { useEffect, useState } from 'react';
import styles from './ModalPromo.module.scss';
import ArrowRightIcon from '@/assets/icons/pay/ArrowRightIcon';
import CrossIcon from '@/assets/icons/pay/CrossIcon';
import { CustomTextField } from '@/components/styled/CustomTextField';
import { calcPrice } from '@/utils/calc-price';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { useCheckUserPromocodeLazyQuery } from '@/generated/projectlb-hasura';
import coin from '@/assets/icons/HDCoinSmall.svg';
import { useAmpliError } from '@/hooks/use-ampli-error';
import HyperRub from '@/assets/icons/hyperRub';
import CentreButton from '@/components/styled/CustomButtons/CentreButton/CentreButton';
import Image from 'next/image';
import give from '@/assets/images/promoMain/give.webp';
import giveWin from '@/assets/images/promoMain/giveWin.webp';
import fire from '@/assets/icons/promoMain/fire.svg';
import { getTicketImage } from '@/components/MainPage/BannerCards/BannerCards';
import { track } from '@amplitude/analytics-browser';
import { setReturnPageCookie } from '@/utils/set-return-page-cookie';
import UnauthPromoCheck from '@/components/Authorized/PromoBlock/ModalWritePromo/UnauthPromoCheck/UnauthPromoCheck';
import posthog from 'posthog-js';

interface IPayModal {
  openPay: () => void;
}

type FormData = {
  pay: number;
  promocode?: string;
};

const symbolByType: { [key: number]: JSX.Element } = {
  1: (
    <span>
      <HyperRub />
    </span>
  ),
  2: <span>%</span>,
  3: <span>HD</span>,
};

const ticketTitle = {
  iron: 'Железный',
  silver: 'Серебряный',
  gold: 'Золотой',
};

function Timer() {
  const [timeLeft, setTimeLeft] = useState(24 * 60 * 60);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  return (
    <div className={styles.timerDefault}>
      <Image src={fire} alt="Fire" />
      <p>Предложение актуально</p>
      <p>
        {hours.toString().padStart(2, '0')}:
        {minutes.toString().padStart(2, '0')}:
        {seconds.toString().padStart(2, '0')}
      </p>
    </div>
  );
}

const ModalPromo = (props: IPayModal) => {
  const [showUnauthModal, setShowUnauthModal] = useState(false);
  const { accessToken, isAuthorized } = useAuthContext();
  const router = useRouter();
  const { errorTrack } = useAmpliError();

  const local_data = isAuthorized
    ? JSON.parse(String(sessionStorage?.getItem('local_pm_data')))
    : '';

  const initialPromocode = router.query['promocode'];

  const { watch, setValue } = useForm<FormData>({
    defaultValues: {
      pay: local_data ? Number(local_data.local_pay) : 0,
      promocode:
        initialPromocode || (local_data ? local_data?.local_promo : ''),
    },
  });

  const watchPay = watch('pay');
  const watchPromocode = watch('promocode');

  useEffect(() => {
    if (initialPromocode) {
      setValue('promocode', String(initialPromocode), {});
      submitPromocode({ pay: watchPay, promocode: String(initialPromocode) });
    }
  }, [initialPromocode]);

  useEffect(() => {
    sessionStorage.setItem(
      'local_pm_data',
      JSON.stringify({
        local_promo: watchPromocode,
        local_pay: watchPay,
        local_type_pay: local_data ? Number(local_data.local_type_pay) : 0,
        local_type_currency: local_data
          ? Number(local_data.local_type_currency)
          : 0,
      })
    );
  }, [watchPay, watchPromocode]);

  const [checkPromocode, { data: dataPromo, loading: loadingPromo }] =
    useCheckUserPromocodeLazyQuery({
      context: { token: accessToken },
      onError() {
        errorTrack({
          message: 'Ошибка применения промокода',
        });
        return toast.error('Произошла ошибка');
      },
      onCompleted(data) {
        const res = data.CheckUserPromocode;
        !!res?.error &&
          errorTrack({
            message: res.error,
          });
      },
    });

  const submitPromocode = async (data: FormData) => {
    const { promocode } = data;
    await checkPromocode({
      variables: {
        promocode: promocode || watchPromocode || '',
      },
    });
  };

  const handleActivatePromocode = async () => {
    if (!isAuthorized) {
      setShowUnauthModal(true);
      setReturnPageCookie(router.asPath, true);
    } else {
      await submitPromocode({ pay: watchPay, promocode: watchPromocode });
      track('activate promocode modal', { auser: isAuthorized });
      posthog.capture('activate promocode modal', {
        auser: isAuthorized,
      });
    }
  };

  if (showUnauthModal) {
    return <UnauthPromoCheck promocode={watchPromocode} />;
  }

  if (dataPromo?.CheckUserPromocode?.success) {
    return (
      <div className={styles.containerPromo}>
        <div className={styles.containerPromoImageWin}>
          <Image src={giveWin} alt="Party popper" />
        </div>
        <div className={styles.helperText_container}>
          <h2 className={styles.successHeader}>Поздравляем!</h2>
          <div className={styles.conHelpText}>
            <div className={styles.helpText_one}>
              <p className={styles.text_success}>
                Вы получите
                {dataPromo.CheckUserPromocode.reward_type! && (
                  <Image
                    src={getTicketImage(
                      dataPromo.CheckUserPromocode.reward_type!
                    )}
                  />
                )}
                {dataPromo.CheckUserPromocode.type === 1
                  ? calcPrice(dataPromo.CheckUserPromocode.value)
                  : ''}{' '}
                {dataPromo.CheckUserPromocode.type === 2 &&
                  `+${dataPromo.CheckUserPromocode.value}`}
                {dataPromo.CheckUserPromocode.type === 3 && (
                  <div className={styles.coin}>
                    <Image src={coin} alt="Coin" />
                    {dataPromo.CheckUserPromocode.value}
                  </div>
                )}
                {dataPromo.CheckUserPromocode.type === 4 &&
                  `${
                    dataPromo.CheckUserPromocode.reward_type &&
                    ticketTitle[
                      dataPromo.CheckUserPromocode
                        .reward_type as keyof typeof ticketTitle
                    ]
                  } Билет`}
                {dataPromo.CheckUserPromocode.type === 5 &&
                  `${dataPromo.CheckUserPromocode.reward_type}`}
                {symbolByType[dataPromo.CheckUserPromocode.type || 0]}
                {dataPromo.CheckUserPromocode.type === 2 && `к пополнению`}
                {dataPromo.CheckUserPromocode.type === 3 && (
                  <>
                    <p>Coin</p>
                  </>
                )}
              </p>
            </div>
            {(dataPromo.CheckUserPromocode.min_deposit ||
              dataPromo.CheckUserPromocode.max_amount ||
              dataPromo.CheckUserPromocode.type === 4) && (
              <div className={styles.helpText_one}>
                <div>
                  {dataPromo.CheckUserPromocode.min_deposit && (
                    <p className={styles.text}>
                      Пополните баланс от{' '}
                      {calcPrice(dataPromo.CheckUserPromocode.min_deposit)} ₽,
                      чтобы получить подарок.
                    </p>
                  )}
                  {dataPromo.CheckUserPromocode.max_amount && (
                    <p className={styles.text}>
                      Максимальный бонус составит{' '}
                      {calcPrice(dataPromo.CheckUserPromocode.max_amount)}
                      {' ₽'}
                    </p>
                  )}
                  {dataPromo.CheckUserPromocode.type === 4 && (
                    <p className={styles.text}>
                      Максимальное количество билетов 5. Лишние билеты из
                      промокода сгорают
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <CentreButton text={'Забрать'} onClick={props.openPay} />
        <Timer />
      </div>
    );
  }

  return (
    <div className={styles.containerPromo}>
      <div className={styles.containerPromoImage}>
        <Image src={give} />
      </div>
      <div className={styles.containerPromoInpTxt}>
        <div className={styles.containerPromoTxt}>
          <p>Укажи</p>
          <div className={styles.containerPromoTxtPromo}>
            <p>ПРОМОКОД</p>
          </div>
          <p>и получи подарок</p>
        </div>
        <div className={styles.promocodeInput}>
          <div
            className={styles.promocode_input_container}
            style={{
              border:
                dataPromo?.CheckUserPromocode &&
                !dataPromo?.CheckUserPromocode?.success
                  ? '1px solid #f44141'
                  : '1px solid rgba(147, 155, 194, 0.32)',
            }}
          >
            <CustomTextField
              fullWidth
              label="Введите промокод"
              variant="filled"
              type="text"
              id="promo"
              value={watch('promocode') || ''}
              onChange={(e) => setValue('promocode', e.target.value)}
            />
          </div>

          {dataPromo?.CheckUserPromocode &&
            !dataPromo.CheckUserPromocode.success && (
              <div className={styles.helperText_container}>
                <div className={styles.helpText_one}>
                  <CrossIcon />
                  <p className={styles.text_error}>
                    Промокод не найден или его срок действия истек
                  </p>
                </div>
              </div>
            )}
        </div>
      </div>

      <CentreButton
        onClick={handleActivatePromocode}
        id="promo"
        loading={loadingPromo}
        disabled={watchPromocode?.length === 0}
        icon={<ArrowRightIcon />}
        text="Активировать"
      />
    </div>
  );
};

export default ModalPromo;
