import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './TimerBlock.module.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const dateToUtc = () => dayjs().utc().valueOf();

export const getLabel = (timeLeft: number, startDate: number) => {
  if (timeLeft <= 0) {
    return 'Завершено';
  } else if (startDate <= dateToUtc()) {
    return 'до розыгрыша';
  } else {
    return 'до старта';
  }
};

const getTimeObject = (time: number) => ({
  days: Math.floor(time / 86400),
  hours: Math.floor((time % 86400) / 3600),
  minutes: Math.floor((time % 3600) / 60),
  seconds: time % 60,
});

const TimerBlock = ({
  endDate,
  startDate,
}: {
  endDate: number;
  startDate: number;
}) => {
  const [timeLeftEnd, setTimeLeftEnd] = useState(
    Math.max(0, Math.floor((startDate - dateToUtc()) / 1000))
  );
  const [timeLeft, setTimeLeft] = useState(
    Math.max(0, Math.floor((endDate - dateToUtc()) / 1000))
  );

  const label = getLabel(timeLeft, startDate);

  const progressValue =
    label === 'до старта'
      ? Math.min(100, ((startDate - dateToUtc()) / (endDate - startDate)) * 100)
      : Math.max(0, ((endDate - dateToUtc()) / (endDate - startDate)) * 100);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeftEnd((prev) => Math.max(0, prev - 1));
      setTimeLeft((prev) => Math.max(0, prev - 1));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const progressColor =
    progressValue > 75 ? 'green' : progressValue > 50 ? 'orange' : 'red';

  const timeObject =
    label === 'до розыгрыша'
      ? getTimeObject(timeLeft)
      : getTimeObject(timeLeftEnd);
  const { days, hours, minutes, seconds } = timeObject;

  return (
    <div className={styles.container_date}>
      <div className={styles.circularProgress}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={`${styles.date_circle} ${styles.date_circle_outline}`}
          style={{ color: '#0c1027' }}
          thickness={8}
        />

        <CircularProgress
          variant="determinate"
          value={label !== 'Завершено' ? progressValue : 0}
          className={styles.date_circle}
          style={{ color: progressColor }}
          thickness={8}
        />
      </div>

      <div className={styles.container_date_info}>
        <p className={styles.timer_text}>
          {days > 0 && `${days}д `}
          {hours.toString().padStart(2, '0')}:
          {minutes.toString().padStart(2, '0')}:
          {seconds.toString().padStart(2, '0')}
        </p>
        <p className={styles.timer_label}>{label}</p>
      </div>
    </div>
  );
};

export default TimerBlock;
